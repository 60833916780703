// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable no-plusplus */
import { SubText, TitleH2, TitleH3 } from 'components/Common/Typography'
import { FC, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
// import Clock from 'react-live-clock'
import AOS from 'aos'
import Image from 'next/image'
// import imageContactInfoIcon2 from '../../../../public/assets/images/contact-info-icon-2.svg'
// import imageContactInfoIcon3 from '../../../../public/assets/images/contact-info-icon-3.svg'
// import imageContactInfoIcon4 from '../../../../public/assets/images/contact-info-icon-4.svg'
// import imageContactInfoIcon5 from '../../../../public/assets/images/contact-info-icon-5.svg'
// import imageContactInfoIcon6 from '../../../../public/assets/images/contact-info-icon-6.svg'
// import imageContactInfoIconIndia from '../../../../public/assets/images/contact-info-icon-india.svg'

const imageContactInfoIcon2 = '/assets/images/contact-info-icon-2.svg'
const imageContactInfoIcon3 = '/assets/images/contact-info-icon-3.svg'
const imageContactInfoIcon4 = '/assets/images/contact-info-icon-4.svg'
const imageContactInfoIcon5 = '/assets/images/contact-info-icon-5.svg'
const imageContactInfoIcon6 = '/assets/images/contact-info-icon-6.svg'
const imageContactInfoIconIndia = '/assets/images/contact-info-icon-india.svg'

const ContactInformation: FC<{ data }> = ({ data }) => {
  useEffect(() => {
    AOS.init()
  }, [])

  function iconAnimation() {
    const reveals = document.querySelectorAll('.hvr-icon-pop img')
    for (let i = 0; i < reveals.length; i++) {
      const windowHeight = window.innerHeight
      const elementTop = reveals[i].getBoundingClientRect().top
      const elementVisible = 300

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add('hvr-icon-contact')
      } else {
        reveals[i].classList.remove('hvr-icon-contact')
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', iconAnimation)
  }, [])

  return (
    <section className="contact_information">
      <Container>
        <Row className="justify-content-center">
          <Col lg={8}>
            <div data-aos="fade-up">
              {data?.title ? (
                <TitleH3
                  className="text_black title_md mb-2 mb-md-3 text-center"
                  text={data?.title}
                />
              ) : (
                <TitleH3
                  className="text_black mb-2 mb-md-3 text-center"
                  text="Regional Contact Info"
                />
              )}
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              {data?.subTitle ? (
                <SubText
                  className="mb-4 pb-1 text-center"
                  text={data?.subTitle}
                />
              ) : (
                <SubText
                  className="mb-4 pb-1 text-center"
                  text="We can give you the Wings to Fly. Contact us now!"
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col md={4} className="px-xl-0 bdr_r">
            <div
              className="inner_block px-4 px-xl-5 pb-4 pt-2 bdr_b_l"
              data-aos="zoom-in"
            >
              <div className="d-lg-flex pt-3">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">India</h3>
                  {/* <p className="text1">
                     <Clock format="h:mm A" ticking timezone="Asia/Kolkata" /> 
                  </p> */}
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="400"
                    data-aos-once="true"
                    src={imageContactInfoIconIndia}
                    alt="India"
                    width={104}
                    height={121}
                    style={{
                      maxWidth: '88px',
                      minWidth: '70px',
                      width: '4.2vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">Ahmedabad</h3>
              <p className="text_para mb-4">
                1007-1010, Signature-1, <br />
                S.G.Highway, Makarba, <br />
                Ahmedabad, Gujarat - 380051
              </p>
              <h3 className="text_para_large mb-2 fw-600">Rajkot</h3>
              <p className="text_para">
                1308 - The Spire, 150 Feet Ring Rd, <br />
                Manharpura 1, Madhapar, Rajkot, Gujarat - 360007
              </p>
            </div>
            <div
              className="inner_block px-4 px-xl-5 pt-3 pb-4 pb-md-0"
              data-aos="zoom-in"
            >
              <div className="d-lg-flex pt-3">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">UAE</h3>
                  <p className="text1">
                    {/* <Clock format="h:mm A" ticking timezone="Etc/Greenwich" /> */}
                  </p>
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="500"
                    data-aos-once="true"
                    src={imageContactInfoIcon6}
                    alt="UAE"
                    width={107}
                    height={107}
                    style={{
                      maxWidth: '100px',
                      minWidth: '80px',
                      width: '4.5vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">Dubai</h3>
              <p className="text_para">
                Dubai Silicon Oasis, DDP, <br />
                Building A1, Dubai, UAE
              </p>
            </div>
          </Col>
          <Col md={4} className="bdr_r px-0">
            <div
              className="inner_block px-4 px-xl-5 bdr_btm pb-4 pt-4"
              data-aos="zoom-in"
            >
              <div className="d-lg-flex">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">USA</h3>
                  {/* <p className="text1">
                    <Clock
                      format="h:mm A"
                      ticking
                      timezone="America/New_York"
                    />
                  </p> */}
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="300"
                    data-aos-once="true"
                    className=""
                    src={imageContactInfoIcon2}
                    alt="USA"
                    width={115}
                    height={115}
                    style={{
                      maxWidth: '110px',
                      minWidth: '78px',
                      width: '5vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">Delaware</h3>
              <p className="text_para mb-4">8 The Green, Dover DE, 19901, USA</p>
              <h3 className="text_para_large mb-2 fw-600">New Jersey</h3>
              <p className="text_para mb-4">
                513 Baldwin Ave, Jersey City,
                <br />
                NJ 07306, USA
              </p>

              <h3 className="text_para_large mb-2 fw-600">California</h3>
              <p className="text_para pb-3 pb-lg-0">
                4701 Patrick Henry Dr. Building <br />
                26 Santa Clara, California 95054
              </p>
            </div>
            <div
              className="inner_block brdr_btm_as px-4 px-xl-5 pt-3 pb-4 pb-md-0"
              data-aos="zoom-in"
            >
              <div className="d-lg-flex pt-3">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">Australia</h3>
                  {/* <p className="text1">
                     <Clock
                      format="h:mm A"
                      ticking
                      timezone="Australia/Brisbane"
                    /> 
                  </p> */}
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="300"
                    data-aos-once="true"
                    src={imageContactInfoIcon5}
                    alt="Australia"
                    width={106}
                    height={101}
                    style={{
                      maxWidth: '120px',
                      minWidth: '80px',
                      width: '4.5vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">Queensland</h3>
              <p className="text_para">
                120 Highgate Street, Coopers Plains, Brisbane, Queensland 4108
              </p>
            </div>
          </Col>
          <Col md={4} className="px-0">
            <div
              className="inner_block ps-3 ps-lg-5 bdr_h pb-4 pt-4"
              data-aos="zoom-in"
            >
              <div className="d-lg-flex pt-2">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">UK</h3>
                  <p className="text1">
                    {/* <Clock format="h:mm A" ticking timezone="Etc/Greenwich" /> */}
                  </p>
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="500"
                    data-aos-once="true"
                    src={imageContactInfoIcon3}
                    alt="UK"
                    width={107}
                    height={97}
                    style={{
                      maxWidth: '120px',
                      minWidth: '80px',
                      width: '4.8vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">London</h3>
              <p className="text_para">
                85 Great Portland Street, First <br />
                Floor, London, W1W 7LT
              </p>
            </div>

            <div className="inner_block ps-3 pt-3 ps-lg-5" data-aos="zoom-in">
              <div className="d-lg-flex pt-3">
                <div className="col">
                  <h3 className="title1 mb-2 mb-lg-3">Canada</h3>
                  <p className="text1">
                    {/* <Clock format="h:mm A" ticking timezone="America/Cancun" /> */}
                  </p>
                </div>
                <div className="col-auto mb-4 mb-lg-0 icon_ftr">
                  <Image
                    data-aos="icon-anim1"
                    data-aos-delay="300"
                    data-aos-once="true"
                    src={imageContactInfoIcon4}
                    alt="Canada"
                    width={73}
                    height={73}
                    style={{
                      maxWidth: '90px',
                      minWidth: '80px',
                      width: '4.2vw',
                      height: 'auto',
                    }}
                  />
                </div>
              </div>
              <h3 className="text_para_large mb-2 fw-600">Burlington</h3>
              <p className="text_para">
                5096 South Service Rd,
                <br />
                ON Burlington, L7l 4X4
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default ContactInformation
